<template>
  <vx-card>
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :hide-buttons="true"
      title="DOCUMENTOS"
      subtitle="Por favor complete los campos del formularios de ingreso de documentos"
    >
      <form data-vv-scope="general-data" @submit.prevent>
        <vs-divider class="vx-col w-full" position="left" color="light">Datos Generales</vs-divider>

        <div class="vx-row mb-5">
          <div class="vx-col md:w-1/2 w-full">
            <label for="name" class="w-full select-large">Nombre</label>
            <vs-input
              v-model="models.entryModel.name"
              autocomplete="off"
              class="w-full"
              name="name"
              v-validate="'required'"
            />
            <span class="text-danger">{{ errors.first("general-data.name") }}</span>
          </div>

          <div class="vx-col md:w-1/2 w-full">
            <label for="title" class="w-full select-large">Título</label>
            <vs-input
              id="title"
              v-model="models.entryModel.title"
              autocomplete="off"
              class="w-full"
              name="title"
              v-validate="'required'"
            />
            <span class="text-danger">{{ errors.first("general-data.title") }}</span>
          </div>
        </div>

        <div class="vx-row mb-5">
          <div class="vx-col md:w-1/3 w-full">
            <label for="institution" class="w-full select-large">Institución</label>
            <v-select
              id="institution"
              v-model="models.entryModel.institution"
              class="w-full"
              name="institution"
              label="name"
              v-validate="'required'"
              placeholder="Seleccione..."
              :searchable="false"
              :options="institutions"
              :reduce="(option) => option.id"
            />
            <span class="text-danger">{{ errors.first("general-data.institution") }}</span>
          </div>

          <div class="vx-col md:w-1/3 w-full">
            <label for="category" class="w-full select-large">Categoría</label>
            <div class="w-full flex">
              <v-select
                id="category"
                v-model="models.entryModel.category"
                class="vx-col w-10/12 xl:w-11/12 select-large px-0"
                index="id"
                label="name"
                name="category"
                :options="categories"
                placeholder="Seleccione..."
                :searchable="true"
                v-validate="'required'"
                @input="loadSubcategories($event)"
              />
              <vs-button
                class="vx-col w-2/12 xl:w-1/12 mt-1 mx-auto select-large"
                color="success"
                icon="icon-plus"
                icon-pack="feather"
                radius
                size="small"
                @click="showCreateCategoryPrompt=!showCreateCategoryPrompt"
              />
            </div>
            <span class="text-danger">{{ errors.first("general-data.category") }}</span>
          </div>

          <div class="vx-col md:w-1/3 w-full">
            <label for="subcategory" class="w-full select-large">Sub-Categoría</label>
            <div class="w-full flex px-0">
              <v-select
                id="subcategory"
                v-model="models.entryModel.subcategory"
                class="vx-col w-10/12 xl:w-11/12 select-large px-0"
                index="id"
                label="name"
                name="subcategory"
                :options="subcategories"
                placeholder="Seleccione..."
                :searchable="true"
                v-validate="'required'"
              />
              <vs-button
                class="vx-col w-2/12 xl:w-1/12 mt-1 mx-auto select-large"
                color="success"
                icon="icon-plus"
                icon-pack="feather"
                radius
                size="small"
                @click="showCreateSubcategoryPrompt=!showCreateSubcategoryPrompt"
              />
            </div>
            <span class="text-danger">{{ errors.first("general-data.subcategory") }}</span>
          </div>
        </div>

        <div class="vx-row mb-5">
          <div class="vx-col w-full flex">
            <vs-checkbox v-model="is_external" danger @click="resetModel('entry.media')"/>
            Es documento externo
          </div>
        </div>

        <div class="vx-row mb-5" v-if="is_external">
          <div class="vx-col md:w-2/3 w-full mb-5">
            <label for="name" class="w-full select-large">URL</label>
            <vs-input
              id="url"
              v-model="models.entryModel.media.file.url"
              autocomplete="off"
              class="w-full"
              name="url"
              type="text"
              v-validate="'required'"
            />
            <span class="text-danger">{{ errors.first("general-data.url") }}</span>
          </div>

          <div class="vx-col md:w-1/3 w-full mb-5">
            <label for="target" class="w-full select-large">Mostrar documento en:</label>
            <v-select
                id="target"
                v-model="models.entryModel.media.file.target"
                class="w-full"
                name="target"
                v-validate="'required'"
                placeholder="Seleccione..."
                :searchable="false"
                :options="[{value:'_blank', label:'Ventana nueva'}, {value:'_self', label:'Ventana actual'}]"
                :reduce="(option) => option.value"
            />
            <span class="text-danger">{{ errors.first("general-data.target") }}</span>
            <br/>
          </div>
        </div>

        <div class="vx-row mb-5" v-else>
          <vs-divider class="vx-col w-full mt-5" position="left" color="light">Cargar documento</vs-divider>
          <div class="vx-col w-full mb-5">
            <uploader
              class-file="file-upload-1"
              :downloaded-files="models.entryModel.media.file"
              :max-number-of-files="1"
              :max-file-size="26214400"
              name="media"
              v-validate="'required'"
              @files-removed="removedFiles('file')"
              @files-updated="fileUpload"
            />
            <a
              v-if="models.entryModel.media.file?.url" class="block mt-5 pl-2 preview"
              :href="models.entryModel.media.file.url" target="_blank"
            >
              Ver documento cargado
            </a>
            <span class="text-danger">{{ errors.first("general-data.media") }}</span>
          </div>
        </div>

        <div class="vx-row mb-5">
          <div class="vx-col w-full">
            <label for="description" class="w-full select-large">Descripción de documento</label>
            <editor
              id="description"
              :content.sync="models.entryModel.description.html"
              name="description"
              :resourceType="resource"
              v-validate="'required'"
            />
            <span class="text-danger">{{ errors.first("general-data.description") }}</span>
          </div>
        </div>

        <div class="vx-row mb-5">
          <div class="vx-col md:w-1/2 w-full">
            <label for="visibility_status" class="w-full select-large">Visibilidad</label>
            <v-select
              id="visibility_status"
              v-model="models.entryModel.visibility_status"
              class="w-full"
              name="visibility_status"
              v-validate="'required'"
              placeholder="Seleccione..."
              :searchable="false"
              :options="[{value:'visible', label:'Visible'}, {value:'hidden', label:'Oculto'}]"
              :reduce="(option) => option.value"
            />
            <span class="text-danger">{{ errors.first("general-data.visibility_status") }}</span>
          </div>

          <div class="vx-col md:w-1/2 w-full">
            <label for="featured" class="w-full select-large">Destacado</label>
            <v-select
                id="featured"
                v-model="models.entryModel.relevance"
                class="w-full"
                name="featured"
                v-validate="'required'"
                placeholder="Seleccione..."
                :searchable="false"
                :options="[{value:'featured', label:'Si'}, {value:'normal', label:'No'}]"
                :reduce="(option) => option.value"
            />
            <span class="text-danger">{{ errors.first("general-data.featured") }}</span>
          </div>
        </div>

        <vs-divider class="vx-col w-full" position="left" color="light">Publicación</vs-divider>

        <div class="vx-row">
          <div class="vx-col md:w-1/3 w-full">
            <label for="publish_at" class="w-full select-large">Fecha de publicación</label>
            <div class="w-full flex">
              <flat-pickr
                  id="publish_at"
                  ref="flat_pickr_publish_at"
                  v-model="models.entryModel.publish_at"
                  class="w-full flatpickr flatpickr-input"
                  name="publish_at"
                  placeholder="Fecha y hora de publicación"
                  v-bind="componentOptions.flatPickr"
                  v-validate="'required'"
              />
              <feather-icon
                  icon="XIcon" class="date-reset-icon" svgClasses="w-5 h-5"
                  @click="models.entryModel.publish_at=null"
              />
            </div>
            <span class="text-danger">{{ errors.first("general-data.publish_at") }}</span>
          </div>

          <div class="vx-col md:w-1/3 w-full">
            <label for="expire_at" class="w-full select-large">Fecha de expiración</label>
            <div class="w-full flex">
              <flat-pickr
                  id="expire_at"
                  v-model="models.entryModel.expire_at"
                  class="w-full flatpickr flatpickr-input"
                  name="expire_at"
                  placeholder="Fecha y hora de expiración"
                  v-bind="componentOptions.flatPickr"
              />
              <feather-icon
                  icon="XIcon" class="date-reset-icon" svgClasses="w-5 h-5"
                  @click="models.entryModel.expire_at=null"
              />
            </div>
          </div>

          <div class="vx-col md:w-1/3 w-full">
            <label for="delete_at" class="w-full select-large">Fecha de borrado programado</label>
            <div class="w-full flex">
              <flat-pickr
                  id="delete_at"
                  v-model="models.entryModel.delete_at"
                  class="w-full flatpickr flatpickr-input"
                  name="delete_at"
                  placeholder="Fecha y hora de borrado"
                  v-bind="componentOptions.flatPickr"
              />
              <feather-icon
                  icon="XIcon" class="date-reset-icon" svgClasses="w-5 h-5"
                  @click="models.entryModel.delete_at=null"
              />
            </div>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mt-5">
            <div class="flex flex-wrap justify-between">
              <vs-row vs-type="flex" vs-justify="flex-end">
                <vs-button color="danger" type="border" class="mb-2" @click="goToEntriesList">Cancelar</vs-button>
                <vs-button class="ml-3 mb-2" @click="createOrUpdateEntry">Guardar</vs-button>
              </vs-row>
            </div>
          </div>
        </div>
      </form>

      <vs-prompt
        :title="'Añadir Categoría'"
        :accept-text="'Añadir'"
        :cancel-text="'Cancelar'"
        :active.sync="showCreateCategoryPrompt"
        @accept="createCategory"
        @cancel="resetModel('category')"
      >
        <div class="vx-row">
          <div class="vx-col w-full">
            <label for="name">Nombre Categoría:</label>
            <vs-input id="category-name" v-model="models.categoryModel.name" class="w-full" name="category-name"/>
          </div>
        </div>
      </vs-prompt>

      <vs-prompt
        :title="'Añadir Subcategoría'"
        :accept-text="'Añadir'"
        :cancel-text="'Cancelar'"
        :active.sync="showCreateSubcategoryPrompt"
        @accept="createSubcategory"
        @cancel="resetModel('subcategory')"
      >
        <div class="vx-row">
          <div class="vx-col w-full">
            <label for="name">Nombre Subcategoría:</label>
            <vs-input name="subcategory-name" id="subcategory-name" v-model="models.subcategoryModel.name" class="w-full"/>
          </div>
        </div>

        <div class="vs-row">
          <div class="vx-col w-full">
            <label for="subcategory-category">Categoría Padre:</label>
            <v-select
              id="subcategory-category"
              v-model="models.subcategoryModel.category"
              class="vx-col w-full select-large"
              index="id"
              label="name"
              name="subcategory-category"
              :options="categories"
              :placeholder="'Seleccione...'"
              :searchable="true"
            />
          </div>
        </div>
      </vs-prompt>

    </form-wizard>
  </vx-card>
</template>

<script>
  import {cloneDeep} from "lodash";
  import {FormWizard, TabContent} from 'vue-form-wizard';
  import vSelect from "vue-select";
  import flatPickr from "vue-flatpickr-component";
  import Configurations from "./../../utils/configurations";
  import Editor from "./../../components/Editor";
  import DocumentsClient from "./../../utils/documentsClient";
  import Notifier from "../../utils/notification";
  import Upload from "@/utils/upload";
  import CategoriesClient from "@/utils/categoriesClient";
  import SubCategoriesClient from "@/utils/subcategoriesClient";
  import CoreClient from "@/utils/coreClient";

  let configurations = new Configurations();
  let notifier = new Notifier();
  const documentsClient = new DocumentsClient();
  const categoriesClient = new CategoriesClient();
  const subcategoriesClient = new SubCategoriesClient();
  const uploadsClient = new Upload("resource");
  const institutionsClient = new CoreClient("institutions");

  const entryModelDefault = {
    name: "",
    category: null,
    description: {
      html: ""
    },
    relevance: "normal",
    media: {
      file: {
        url: "",
        target: "_blank",
        meta: {},
      },
    },
    publication_status: "unpublished",
    source_type: "document",
    subcategory: null,
    title: "",
    institution: "",
    visibility_status: "visible",
  };
  const categoryDefault = {
    name: "",
  };
  const subcategoryDefault = {
    name: "",
    category: {...categoryDefault},
  };

  export default {
    components: {
      Editor,
      flatPickr,
      FormWizard,
      TabContent,
      "v-select": vSelect,
    },

    data() {
      return {
        categories: [],
        componentOptions: {
          flatPickr: {
            config: configurations.configDatePicker(),
          }
        },
        institutions: [],
        is_external: false,
        models: {
          categoryModel: cloneDeep(categoryDefault),
          entryModel: cloneDeep(entryModelDefault),
          subcategoryModel: cloneDeep(subcategoryDefault),
        },
        resource: "documents",
        showCreateCategoryPrompt: false,
        showCreateSubcategoryPrompt: false,
        subcategories: [],
        uploadedFiles: null,
      }
    },

    watch: {
      is_external(newVal) {
        this.models.entryModel.source_type = newVal ? "link" : "document";
      },
    },

    async created() {
      try{
        this.$vs.loading();

        await this.getInstitutions();
        this.categories = await this.getCategories();

        if (this.$route.params.id) {
          const entryResponse = await documentsClient.read({pathParameters: {id: this.$route.params.id}});
          this.models.entryModel = entryResponse.data

          this.is_external = this.models.entryModel.source_type === "link";
          this.models.entryModel.publish_at = this.models.entryModel.publish_at ? this.models.entryModel.publish_at : "";

          if (this.models.entryModel.category){
            this.getSubcategories(this.models.entryModel.category.id).then((response) => {
              this.subcategories = response;
              this.$vs.loading.close();
            }).catch(()=>{
              notifier.notification("error");
              this.$vs.loading.close();
            });
          }
        }

        this.$vs.loading.close();
      } catch (_) {
        this.$vs.loading.close();
        await notifier.notification("error");
      }
    },

    methods: {
      async createOrUpdateEntry() {
        try{
          const isFormValid = await this.$validator.validateAll("general-data");

          if (isFormValid) {
            this.$vs.loading();
            if (this.models.entryModel.id) {
              await documentsClient.update({
                pathParameters: {id: this.models.entryModel.id},
                data: this.models.entryModel
              });
            } else {
              await documentsClient.create({data: this.models.entryModel})
            }

            this.$vs.loading.close();
            await this.goToEntriesList();
            await notifier.notification(this.models.entryModel.id ? "updated" : "created");
          }
        } catch (_) {
          this.resetModel("entry");
          this.$vs.loading.close();
          await notifier.notification("error")
        }
      },

      async getInstitutions(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await institutionsClient.all()
          .then(response => {
            this.institutions = response.data;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getCategories() {
        try {
          const response =  await categoriesClient.search();
          return response.data.results;
        } catch (_) {
          await notifier.notification("error");
          return [];
        }
      },

      async getSubcategories(category_id) {
        try{
          const response = await subcategoriesClient.search();
          return response.data.results.filter((subcategory) => subcategory.category.id === category_id);
        } catch (_) {
          await notifier.notification("error");
          return [];
        }
      },

      async createCategory() {
        try{
          this.$vs.loading();

          await categoriesClient.create({data: this.models.categoryModel})
          this.categories = await this.getCategories()

          this.$vs.loading.close();
        } catch (_) {
          this.$vs.loading.close();
          await notifier.notification('error');
        }

      },

      async createSubcategory() {
        this.$vs.loading();

        try{
          await subcategoriesClient.create({data: this.models.subcategoryModel});
          this.models.subcategoryModel = {...subcategoryDefault};
          if (this.models.entryModel.category?.id) {
            this.subcategories = await this.getSubcategories(this.models.entryModel.category.id);
          }
          this.$vs.loading.close();
        } catch (_) {
          this.$vs.loading.close();
          await notifier.notification('error');
        }
      },

      async fileUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          try{
            this.$vs.loading();

            this.models.entryModel.media.file.url = await uploadsClient.uploadFiles({
              queryStrings: {resource_type: this.resource, content_type: files[0].type},
              files: files,
              multiple: false,
            });
            this.models.entryModel.media.file.meta = {title: files[0].name};
            this.$vs.loading.close();
          } catch (_) {
            this.$vs.loading.close();
            await notifier.notification("error");
          }
        }
      },

      async removedFiles(file_id) {
        try {
          let body = this.models.entryModel.media?.[file_id]?.url;

          if (body) {
            await uploadsClient.removeFiles({data: {url: body.toString()}})
            this.models.entryModel.media[file_id].url = null;
          }
        } catch (_) {
          await notifier.notification("error");
        }
      },

      async goToEntriesList() {
        await this.$router.push({name:"documents"});
      },

      async loadSubcategories(category){
        this.models.entryModel.subcategory = {};
        if (category) {
          this.$vs.loading();

          this.getSubcategories(category.id).then((response) => {
            this.subcategories = response;
            this.$vs.loading.close();
          }).catch(()=>{
            notifier.notification("error");
            this.$vs.loading.close();
          });
        }
      },

      resetModel(modelName) {
        switch (modelName) {
          case "entry":
            Object.assign(this.models.entryModel, cloneDeep(entryModelDefault));
            break;
          case "entry.media":
            Object.assign(this.models.entryModel.media, cloneDeep(entryModelDefault.media));
            break;
          case "category":
            Object.assign(this.models.categoryModel, cloneDeep(categoryDefault));
            break;
          case "subcategory":
            Object.assign(this.models.subcategoryModel, cloneDeep(subcategoryDefault));
            break;
        }
      },
    },
  }
</script>

<style scoped>
  @import "./../../../node_modules/vue-form-wizard/dist/vue-form-wizard.min.css";
  @import "./../../../node_modules/flatpickr/dist/flatpickr.min.css";

  form .vx-row{
    margin-bottom: 1rem;
  }

  .preview{
    color: #2f7dd9;
  }

  .date-reset-icon {
    margin-left: 5px;
    cursor: pointer;
  }
</style>

<style>
  .uppy-Dashboard-inner {
    z-index: 1;
  }
</style>
